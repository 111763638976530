$(document).ready(function() {
    $(".promo-slider").slick({
        centerMode: true,
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 1000,
        rtl: true
    });

    function numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    $(".form__slider").slider({
        min: 100000,
        max: 15000000,
        step: 10000,
        range: "min",
        value: 500000
    }).on("slide", function(event, ui) {
        $(".form__slider-price span").text(numberWithSpaces(ui.value));
        $(".quiz-form [name=cost]").val(numberWithSpaces(ui.value));
    });

    $("[name=typeValue]").inputmask({
        mask: "+7 (999) 999-99-99",
        greedy: false,
        showMaskOnFocus: true,
        clearIncomplete: true,
    });

    $('.form-way__item').click(function() {
        $("[name=typeValue]").inputmask('remove');

        var $this = $(this),
            type = $this.data('type'),
            $form = $this.closest('form');

        $('.form-way__item').removeClass('form-way__item_active');
        $this.addClass('form-way__item_active');

        $form.find('[name=type]').val(type);

        $form.find('[name=typeValue]').removeClass("form__input_phone");
        $form.find('[name=typeValue]').removeClass("form__input_email");
        $form.find('[name=typeValue]').removeClass("form__input_whatsapp");
        $form.find('[name=typeValue]').removeClass("form__input_viber");
        $form.find('[name=typeValue]').removeClass("form__input_telegram");

        $form.find('[name=typeValue]').addClass("form__input_" + type);

        if(type === "phone")
            $("[name=typeValue]").inputmask({
                mask: "+7 (999) 999-99-99",
                greedy: false,
                showMaskOnFocus: true,
                clearIncomplete: true,
            });

        $form
            .find('[name=typeValue]')
            .attr('placeholder', 'Введите ваш ' + $this.find('span').text() + '*');
    });

    $(".menu__close").click(function() {
        $(".menu").hide();
    });

    $(".header-menu__open").click(function() {
        $(".menu").fadeIn();
    });

    $(".buy-type [type=\"radio\"]").on("change", function() {
        $(".buy__button").attr("data-title", $(this).val());
    });

    $(document).click(function(e) {
        if ($(e.target).closest('.menu').length || $(e.target).closest('.header').length) {
            return;
        }

        $(".menu").hide();
    });

    $('form').submit(function() {
        return false;
    });

    function getFormObject(formArray) {
        var obj = {};
        $.each(formArray, function(i, pair) {
            var cObj = obj,
                pObj,
                cpName;
            $.each(pair.name.split('.'), function(i, pName) {
                pObj = cObj;
                cpName = pName;
                cObj = cObj[pName] ? cObj[pName] : (cObj[pName] = {});
            });
            pObj[cpName] = pair.value;
        });
        return obj;
    }

    function submitForm(form) {
        if ($(form).hasClass('no-valid')) {
            return false;
        }

        var formData = getFormObject($(form).serializeArray());

        axios({
            method: 'post',
            url: '/wp-content/themes/rublevkacars/sender.php',
            data: formData,
            config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
        })
            .then(function(response) {
                ym(73374325, 'reachGoal', 'send_form');
                gtag('event', 'form', { 'event_category': 'form', 'event_action': 'send', });

                $.fancybox.close({});
                $.fancybox.open($('.popup-thanks'), {
                    touch: false,
                    helpers: {
                        thumbs: {
                            width: 50,
                            height: 50,
                        },
                        overlay: {
                            locked: true,
                        },
                    },
                    beforeShow: function() {},
                    afterShow: function() {},
                });
            })
            .catch(function(response) {});
    }

    $('form').each(function() {
        $(this).validate({
            rules: {
                stamp: {
                    required: true,
                },
                model: {
                    required: true,
                },
                year: {
                    required: true,
                },
                vin: {
                    required: true,
                },
                "road-accident": {
                    required: true,
                },
                number: {
                    required: true,
                },
                typeValue: {
                    required: true,
                },
                name: {
                    required: true,
                },
                phone: {
                    required: true,
                },
            },
            submitHandler: function(form) {
                submitForm(form);
            },
        });
    });

    $('body').on('click', '.open-popup', function(e) {
        e.preventDefault();

        var el = $(this).data('popup');
        var $this = $(this);

        $('.popup-' + el).find('[name="formDesc"]').val($(this).data("title"));

        $.fancybox.open($('.popup-' + el), {
            touch: false,
            helpers: {
                thumbs: {
                    width: 50,
                    height: 50,
                },
                overlay: {
                    locked: true,
                },
            },
            beforeShow: function() {},
            afterShow: function() {},
        });
    });

    $(".scroll-to").click(function () {
        var to = $(this).data("to") || $(this).attr("href");

        $(".menu").hide();
        if ($(to).length > 0) {
            $.fancybox.close();

            if (event) event.preventDefault();

            var top = $(to).offset().top + 1;

            $("body, html").animate({scrollTop: top}, 500);
        }
    });

    $("[name='phone']").inputmask({
        mask: "+7 (999) 999-99-99",
        greedy: false,
        showMaskOnFocus: true,
        clearIncomplete: true,
    });

    $(".catalog-filters input, .catalog-filters select").on("change", function() {
        var $stamp = $(".catalog-filters [name='stamp']"),
            $priceFrom = $(".catalog-filters [name='price-from']"),
            $priceTo = $(".catalog-filters [name='price-to']"),
            $runFrom = $(".catalog-filters [name='run-from']"),
            $runTo = $(".catalog-filters [name='run-to']"),
            $isSpectial = $(".catalog-filters [name='is-spectial']");

        $(".products-item").each(function() {
            var price = Number($(this).data("price")),
                stamp = $(this).data("stamp"),
                run = Number($(this).data("run")),
                isSpecial = Boolean($(this).data("is-special"));

            var isFiltered = true;

            if($stamp.val().length > 0) {
                if($stamp.val() !== stamp && isFiltered) isFiltered = false;
            }

            if($priceFrom.val().length > 0 && Number($priceFrom.val()) !== "NaN") {
                if(price < Number($priceFrom.val()) && isFiltered) isFiltered = false;
            }

            if($priceTo.val().length > 0 && Number($priceTo.val()) !== "NaN") {
                if(price > $priceTo.val() && isFiltered) isFiltered = false;
            }

            if($runFrom.val().length > 0 && Number($runFrom.val()) !== "NaN") {
                if(price < $runFrom.val() && isFiltered) isFiltered = false;
            }

            if($runTo.val().length > 0 && Number($runTo.val()) !== "NaN") {
                if(price > $runTo.val() && isFiltered) isFiltered = false;
            }

            if($isSpectial.is(':checked')) {
                if (!isSpecial && isFiltered) isFiltered = false;
            }

            if(isFiltered) {
                $(this).fadeIn();
            } else {
                $(this).hide();
            }
        });
    });

    $(".catalog-filters__reset").click(function() {
        var $stamp = $(".catalog-filters [name='stamp']"),
            $priceFrom = $(".catalog-filters [name='price-from']"),
            $priceTo = $(".catalog-filters [name='price-to']"),
            $runFrom = $(".catalog-filters [name='run-from']"),
            $runTo = $(".catalog-filters [name='run-to']"),
            $isSpectial = $(".catalog-filters [name='is-spectial']");

        $stamp.val('');
        $priceFrom.val('');
        $priceTo.val('');
        $runFrom.val('');
        $runTo.val('');
        $isSpectial.prop('checked', false);

        $(".products-item").fadeIn();
    });

    $(".catalog-info__list-item").click(function() {
        $(".catalog-info__list-item").removeClass("catalog-info__list-item_active");
        $(this).addClass("catalog-info__list-item_active");

        var id = $(this).data("id");

        $('.catalog-info__item').hide();
        $('.catalog-info__item[data-id="' + id + '"]').fadeIn();
    });

    $(".catalog-info__arrow_prev").click(function() {
        var $current = $(".catalog-info__item:visible"),
            id = $current.data("id");

        if($(".catalog-info__item:nth-child(" + (id - 1) + ")").length) {
            $(".catalog-info__item").hide();
            $(".catalog-info__item:nth-child(" + (id - 1) + ")").fadeIn();
        } else {
            $(".catalog-info__item").hide();
            $(".catalog-info__item:last-child").fadeIn();
        }
    });

    $(".catalog-info__arrow_next").click(function() {
        var $current = $(".catalog-info__item:visible"),
            id = $current.data("id");

        if($(".catalog-info__item:nth-child(" + (id + 2) + ")").length) {
            $(".catalog-info__item").hide();
            $(".catalog-info__item:nth-child(" + (id + 2) + ")").fadeIn();
        } else {
            $(".catalog-info__item").hide();
            $(".catalog-info__item:first-child").fadeIn();
        }
    });

    $(".p-product-slider__arrow_prev").click(function() {
        var $parent = $(this).closest(".popup"),
            $currentSlide = $parent.find(".p-product-slider__slide:visible");

        if($parent.find(".p-product-slider__slide:eq(" + ($currentSlide.index() - 1) + ")").length > 0) {
            $parent.find(".p-product-slider__slide").hide();
            $parent.find(".p-product-slider__slide:eq(" + ($currentSlide.index() - 1) + ")").fadeIn();
        } else {
            $parent.find(".p-product-slider__slide").hide();
            $parent.find(".p-product-slider__slide:last-child").fadeIn();
        }
    });

    $(".p-product-slider__arrow_next").click(function() {
        var $parent = $(this).closest(".popup"),
            $currentSlide = $parent.find(".p-product-slider__slide:visible");

        if($parent.find(".p-product-slider__slide:eq(" + ($currentSlide.index() + 1) + ")").length > 0) {
            $parent.find(".p-product-slider__slide").hide();
            $parent.find(".p-product-slider__slide:eq(" + ($currentSlide.index() + 1) + ")").fadeIn();
        } else {
            $parent.find(".p-product-slider__slide").hide();
            $parent.find(".p-product-slider__slide:first-child").fadeIn();
        }
    });

    new WOW().init();
});